(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("@cainiaofe/cn-ui"), require("ReactDOM"));
	else if(typeof define === 'function' && define.amd)
		define(["React", "@cainiaofe/cn-ui", "ReactDOM"], factory);
	else if(typeof exports === 'object')
		exports["CN_BIZ_COMMERCE_COMPONENTS"] = factory(require("React"), require("@cainiaofe/cn-ui"), require("ReactDOM"));
	else
		root["CN_BIZ_COMMERCE_COMPONENTS"] = factory(root["React"], root["CNUI"], root["ReactDOM"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__31__) {
return 