import '../locales/init';
import DWBraftEditorH5 from '@/lowcode/braft-editor-h5';
import DWBraftEditorPc from '@/lowcode/braft-editor-pc';
import DWDetailItem from '@/lowcode/detail-item';
import DWDialogTableCheck from '@/lowcode/dialog-table-check';
import DWEditor from '@/lowcode/editor';
import DWFileImport from '@/lowcode/file-import';
import DWFileShow from '@/lowcode/file-show';
import DWFileUpload from '@/lowcode/file-upload';
import DWImage from '@/lowcode/image';
import DWMergeColumnTable from '@/lowcode/merge-column-table';
import DWSlateEditor from '@/lowcode/slate-editor';
import DWSteps from '@/lowcode/steps';
import DWTableCellEllipsis from '@/lowcode/table-cell-ellipsis';
import DWTableCellImg from '@/lowcode/table-cell-img';
import DWTableStatusColor from '@/lowcode/table-status-color';
import DWTimeline from '@/lowcode/timeline';
import DWH265playerVideo from '@/lowcode/h265player-video';
import DWCustomSelector from '@/lowcode/custom-selector';
import DWFormWinChart from '@/lowcode/form-win-chart';
const bundle = [
    DWDetailItem,
    DWDialogTableCheck,
    DWEditor,
    DWFileShow,
    DWFileImport,
    DWFileUpload,
    DWImage,
    DWMergeColumnTable,
    DWSlateEditor,
    DWSteps,
    DWTableCellEllipsis,
    DWTableCellImg,
    DWTableStatusColor,
    DWTimeline,
    DWBraftEditorPc,
    DWBraftEditorH5,
    DWH265playerVideo,
    DWCustomSelector,
    DWFormWinChart,
];
export default bundle;
