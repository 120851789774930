import { jsx as _jsx } from "react/jsx-runtime";
import $i18n from '@alife/panda-i18n'; /* eslint-disable array-callback-return */
/* eslint-disable no-prototype-builtins */
import { CnTextEllipsis } from '@cainiaofe/cn-ui';
export default function DWTableCellEllipsis(props) {
    const { value, line = 1 } = props;
    if (!value) {
        return '-';
    }
    if (typeof value !== 'string') {
        return (_jsx("span", { style: { color: '#f00' }, children: $i18n.get({
                id: 'TheDataFormatIsNotAString',
                dm: '数据格式不是字符串',
                ns: 'TableCellEllipsis',
            }) }));
    }
    return _jsx(CnTextEllipsis, { line: line, children: value });
}
DWTableCellEllipsis.displayName = 'DWTableCellEllipsis';
export { DWTableCellEllipsis };
