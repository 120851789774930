import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable no-console */
import { useMemo } from 'react';
import { Timeline } from '@cainiaofe/cn-ui';
import dayjs from 'dayjs';
// import { withI18n } from '@alife/panda-i18n';
// import locale from 'src/locale';
import './index.scss';
const TimelineItem = Timeline.Item;
function View(props) {
    const { data, value, mode = 'left' } = props;
    // console.log('1111', value, data);
    // let dataRes = data;
    // if (value) {
    //   dataRes = value;
    // }
    //
    // console.log('dataRes========>', dataRes);
    // if (!Array.isArray(dataRes)) {
    //   console.log('dataRes 数据格式有误 ========>', dataRes);
    //   dataRes = [];
    // }
    const renderTimelineItem = useMemo(() => {
        if (!Array.isArray(data) && !Array.isArray(value)) {
            return null;
        }
        if (Array.isArray(value)) {
            return value?.map((item) => {
                return (_jsx(TimelineItem, { title: item.title, mode: mode, content: item.content, state: item.state, time: dayjs(item.time).format('YYYY-MM-DD HH:mm:ss') }));
            });
        }
        return data?.map((item) => {
            return (_jsx(TimelineItem, { mode: mode, title: item.title, content: item.content, state: item.state, time: dayjs(item.time).format('YYYY-MM-DD HH:mm:ss') }));
        });
    }, [data, mode, value]);
    return _jsx(Timeline, { children: renderTimelineItem });
}
// const DWTimeline = withI18n(View, { locale });
const DWTimeline = View;
DWTimeline.displayName = 'DWTimeline';
export { DWTimeline };
