import CNRequest from 'cn-request';
const axiosConfig = {
    timeout: 0,
};
// request 是一个 axios 实例
const request = CNRequest(axiosConfig, {});
export const getOssKey = async (params) => {
    const res = await request({
        url: '/park/order/resource/upload/sign',
        method: 'post',
        data: { request: { ...params } },
    });
    const { data } = res || {};
    const { code } = data || {};
    if (code === '0000') {
        if (data.data.success) {
            return Promise.resolve(data?.data?.module);
        }
    }
};
/**
 * 上传至oss服务器
 * @param sign
 * @param data { blob } 自定义参数
 * @param fileName {string} 文件名
 */
export const ossUpload = async (sign, data, fileName) => {
    const fd = new FormData();
    fd.append('key', sign.key);
    fd.append('policy', sign.policy);
    fd.append('OSSAccessKeyId', sign.accessId);
    fd.append('signature', sign.signature);
    fd.append('file', data, fileName);
    return request({
        url: sign.host,
        method: 'post',
        data: fd,
        headers: {
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'XMLHttpRequest',
        },
        withCredentials: true,
    });
};
