import { jsx as _jsx } from "react/jsx-runtime";
import $i18n from '@alife/panda-i18n';
import { useMemo, useCallback, useEffect, useState } from 'react';
import { WinChart } from 'win-chart';
const isNextCone = () => {
    return ['cone.cainiao-inc.com'].includes(window.location.hostname);
};
const DataSource = [
    {
        label: $i18n.get({ id: 'Test1', dm: '测试1', ns: 'FormWinChart' }),
        value: 1,
        type: 'a',
    },
    {
        label: $i18n.get({ id: 'Test1', dm: '测试1', ns: 'FormWinChart' }),
        value: 2,
        type: 'a',
    },
    {
        label: $i18n.get({ id: 'Test1', dm: '测试1', ns: 'FormWinChart' }),
        value: 1,
        type: 'a',
    },
];
// window.cnLowCodeUtils?.request?.makeRequest?.({
//   // 请求成功后是否toast提示
//   needSuccessToast: false,
//   buttonConfig:{
//     options: {
//       requestConfig: this.props.requestConfig
//     },
//   },
//   recordDataSource: {},
//   state: this.props?._context?.state || {},
//   // 设置额外请求参数的回调
//   handleParams:()=>{
//     return {...}
//   }
// })
function View(props) {
    const { winChartType, value, height, requestConfig, _context } = props;
    const [curDataSource, setDataSource] = useState([]);
    const getDataSource = useCallback(async () => {
        try {
            const res = await window.cnLowCodeUtils?.request?.makeRequest?.({
                // 请求成功后是否toast提示
                needSuccessToast: false,
                buttonConfig: {
                    options: {
                        requestConfig,
                    },
                },
                recordDataSource: {},
                state: _context?.state || {},
                // 设置额外请求参数的回调
                handleParams: () => {
                    return {};
                },
            });
            const { success, data } = res || {};
            if (success && data) {
                if (Array.isArray(data)) {
                    setDataSource(data);
                }
                if (data?.dataSource && Array.isArray(data?.dataSource)) {
                    setDataSource(data.dataSource);
                }
            }
        }
        catch (error) {
            console.error('get', error);
        }
    }, [_context?.state, requestConfig]);
    useEffect(() => {
        if (requestConfig && requestConfig.serviceType) {
            getDataSource();
        }
    }, [getDataSource, requestConfig]);
    const curValue = useMemo(() => {
        if (isNextCone()) {
            // 如果是搭建页面，直接返回测试数据
            return DataSource;
        }
        if (requestConfig && requestConfig.serviceType) {
            // 配置了请求地址，优先用请求返回地址
            if (curDataSource) {
                return curDataSource;
            }
            return [];
        }
        if (!value || !Array.isArray(value) || value.length === 0) {
            return [];
        }
        return value;
    }, [curDataSource, requestConfig, value]);
    return (_jsx("div", { style: { width: '100%', height }, children: _jsx(WinChart, { chartType: winChartType, data: curValue }) }));
}
const DWFormWinChart = View;
DWFormWinChart.displayName = 'DWFormWinChart';
export { DWFormWinChart };
