import enUS from './en-US.json';
import zhCN from './zh-CN.json';
import zhHK from './zh-HK.json';
import zhTW from './zh-TW.json';
export default {
    'en-US': enUS,
    'zh-CN': zhCN,
    'zh-HK': zhHK,
    'zh-TW': zhTW,
};
