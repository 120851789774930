import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
// import { useState } from 'react';
import { CnAsyncSelect } from '@cainiaofe/cn-ui';
import './index.scss';
import { useEffect, useState } from 'react';
const win = window;
function View(props) {
    const { choiceType = 'single', requestConfig, remoteUrl, titleKey = 'title', contentKey = 'content', linkKey = 'link', readOnly, isPreview, value, _context, style, onChange, showSearch = true, } = props;
    const xValue = props?.['x-value'];
    useEffect(() => {
        // if (readOnly || isPreview) {
        asyncGetRequest();
        // }
    }, []);
    const [dataSource, setDataSource] = useState([]);
    const [cDataSource, setCDataSource] = useState([]);
    const [cValue, setCValue] = useState(xValue || value);
    const asyncGetRequest = async () => {
        try {
            const res = await win.cnLowCodeUtils?.request?.makeRequest?.({
                // 请求成功后是否toast提示
                needSuccessToast: false,
                buttonConfig: {
                    options: {
                        requestConfig: props.requestConfig,
                    },
                },
                recordDataSource: {},
                state: _context?.state || {},
                // 设置额外请求参数的回调
                handleParams: () => {
                    //
                },
            });
            const { success, data: resData } = res || {};
            const data = resData?.dataSource || [];
            if (success) {
                setDataSource(data);
                setCDataSource(data);
                return data;
            }
            setCDataSource(data);
            setDataSource(data);
            return data;
        }
        catch (e) {
            console.error('请求错误', e);
            setDataSource([]);
            setCDataSource([]);
            return [];
        }
    };
    const renderMultiLineText = (text = '') => {
        const renderText = text || '';
        return (_jsx("div", { className: "custom-selector-content", children: renderText.split('\n').map((line, index) => (_jsx("span", { className: "custom-selector-block", children: line }, index))) }));
    };
    function renderCustomItem(item) {
        return (_jsxs("div", { className: "custom-selector-content", children: [_jsx("div", { className: "custom-selector-title", children: item?.[titleKey] || item?.label || '-' }), renderMultiLineText(item?.[contentKey] || '-')] }));
    }
    function renderCustomContent() {
        const allData = Array.isArray(value)
            ? value
                ?.map((cV) => {
                return dataSource?.find((sV) => sV?.value === cV);
            })
                ?.filter((cV) => !!cV) || []
            : [dataSource?.find((sV) => sV?.value === value)]?.filter((cV) => !!cV) || [];
        return (_jsx("div", { style: { width: '100%', ...style }, children: allData.map((item, index) => {
                return (_jsxs("div", { className: "custom-selector-content", children: [_jsx("div", { className: "custom-selector-title", onClick: () => {
                                linkKey && item?.[linkKey] && window.open(item?.[linkKey]);
                            }, children: item?.[titleKey] || item?.label || '-' }), renderMultiLineText(item?.[contentKey] || '-')] }, index));
            }) }));
    }
    function onCustomSearch(v) {
        setCDataSource(dataSource?.filter((item) => {
            if (item?.[titleKey]?.includes(v) ||
                item?.[contentKey]?.includes(v) ||
                item?.value?.includes(v) ||
                item?.['x-value']?.includes(v) ||
                item?.label?.includes(v)) {
                return true;
            }
            return false;
        }));
    }
    // return renderCustomContent();
    return isPreview || readOnly ? (renderCustomContent()) : (_jsx(CnAsyncSelect, { mode: choiceType, className: "custom-selector", popupClassName: "custom-selector-popup", value: cValue, onChange: (v) => {
            setCValue(v);
            onChange(v);
        }, style: { width: '100%', ...style }, 
        // onBlur={console.log.bind(null, 'onBlur')}
        // onToggleHighlightItem={console.log.bind(null, 'onToggleHighlightItem')}
        hasClear: true, itemRender: renderCustomItem, showSearch: showSearch, onSearch: onCustomSearch, 
        // isPreview={true}
        requestConfig: requestConfig, remoteUrl: remoteUrl, dataSource: cDataSource, fillProps: "label" }));
}
const DWCustomSelector = View;
DWCustomSelector.displayName = 'DWCustomSelector';
export { DWCustomSelector };
