import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import $i18n from '@alife/panda-i18n';
/**
 * File: cn-biz-commerce-components-index.tsx
 * Author: zhangruqian
 * Date: 2023/11/23
 * Description: 富文本编辑器
 */
import { useCallback, useEffect, useRef } from 'react';
import { COMPONENT_NAME_ENUM } from '@/const';
// 引入编辑器组件
import BraftEditor from 'braft-editor';
// 引入编辑器样式
import { unitExportFn, unitImportFn } from '@/utils/common';
import { controls, accepts } from './config';
import { isImage, isMp4File } from './tool';
import { request } from 'cn-request';
import { CnMessage } from '@cainiaofe/cn-ui';
import 'braft-editor/dist/index.css';
export default function DWBraftEditorH5(props) {
    const { value, placeholder, readOnly, editorStyle, onChange, maxSize, uploadFileUrl } = props;
    const editor = useRef(null);
    const uploadFn = useCallback(async (innerParam, param) => {
        try {
            const formData = new FormData();
            formData.append('file', param.file, param.file?.name);
            const res = await request({
                url: innerParam.uploadFileUrl,
                method: 'post',
                headers: { 'Content-Type': 'multipart/form-data' },
                data: formData,
            });
            const { success, data, errorMsg } = res || {};
            if (success && data) {
                // 上传成功后调用param.success并传入上传后的文件地址
                param.success({
                    url: data.url,
                    meta: {
                        id: param.id,
                        title: param.file.name,
                        alt: param.file.name,
                        loop: isMp4File(param.file.type),
                        autoPlay: isMp4File(param.file.type),
                        controls: false, // 指定音视频是否显示控制栏
                    },
                });
            }
            else {
                param.error({
                    msg: errorMsg,
                });
            }
        }
        catch (e) {
            console.error('上传失败', e);
            param.error({
                msg: $i18n.get({ id: 'UploadFailed', dm: '上传失败', ns: 'BraftEditorH5' }),
            });
            return Promise.resolve(e);
        }
    }, []);
    const changeHandle = useCallback((content) => {
        onChange(content?.toHTML());
    }, [onChange]);
    const myValidateFn = useCallback((file) => {
        const { type, size } = file;
        if (isImage(type)) {
            if (size > 1024 * 1024 * maxSize) {
                // 判断上传文件是否大于最大
                console.error(`文件不能大于${maxSize}M！`);
                return Promise.reject($i18n.get({
                    id: 'FileCannotBeLargerThanMaxSizeM',
                    dm: '文件不能大于{maxSize}M！',
                    ns: 'BraftEditorH5',
                }, { maxSize }));
            }
            return Promise.resolve();
        }
        CnMessage.error($i18n.get({ id: 'PleaseSelectAPictureFile', dm: '请选择图片文件', ns: 'BraftEditorH5' }));
        return Promise.reject($i18n.get({
            id: 'PleaseSelectAPictureFile.0BK4k',
            dm: '请选择图片文件！',
            ns: 'BraftEditorH5',
        }));
    }, []);
    const myUploadFn = useCallback(async (param) => {
        if (!uploadFileUrl) {
            console.error('请填写上传文件URL');
            return null;
        }
        await uploadFn({ uploadFileUrl }, param);
        return null;
    }, [uploadFileUrl]);
    const myOnInsert = useCallback((info) => {
        console.log('myOnInsert', info);
    }, []);
    useEffect(() => {
        if (value && editor.current && editor.current.getValue()?.toHTML() !== value) {
            editor.current.setValue(BraftEditor.createEditorState(value));
        }
    }, [editor, value]);
    return (_jsx(_Fragment, { children: _jsx(BraftEditor
        // value={innerValue}
        , { 
            // value={innerValue}
            onChange: changeHandle, placeholder: placeholder, readOnly: readOnly, controls: controls, contentStyle: {
                boxShadow: 'inset 0 1px 3px rgba(0,0,0,.1)',
                ...editorStyle,
            }, converts: { unitImportFn, unitExportFn }, ref: editor, media: {
                accepts,
                validateFn: myValidateFn,
                uploadFn: myUploadFn,
                onInsert: myOnInsert,
            } }) }));
}
DWBraftEditorH5.displayName = COMPONENT_NAME_ENUM.DW_BRAFT_EDITOR_H5;
export { DWBraftEditorH5 };
