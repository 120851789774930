import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import $i18n from '@alife/panda-i18n';
/**
 * @author wb-zrq481890
 * @version 1.0.0
 * @Description 编辑器
 * @create 2022/10/21
 */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Editor, Toolbar } from '@wangeditor/editor-for-react';
import { customCheckLinkFn } from '@/components/editor/common';
import { defaultEditorConfig } from '@/components/editor/config';
import { getOssKey, ossUpload } from '@/components/editor/services';
import { CnButton, CnDialog } from '@cainiaofe/cn-ui';
import { COMPONENT_NAME_ENUM } from '@/const';
import styles from './index.module.scss';
import '@wangeditor/editor/dist/css/style.css';
const toolbarConfigExcludeKeys = [
    'uploadImage',
    'group-video',
    'insertTable',
    'codeBlock',
    'bulletedList',
    'numberedList',
    'fontFamily',
];
function View(props) {
    const { editorStyle = { height: '300px' }, title = $i18n.get({ id: 'Preview', dm: '预览', ns: 'Editor' }), onChange = () => { }, previewBtnText, value = '', placeholder, } = props;
    // editor 实例
    const [editor, setEditor] = useState(null); // TS 语法
    // 编辑器内容
    const [html, setHtml] = useState(value);
    // 预览弹窗显示隐藏
    const [visible, setVisible] = useState(false);
    // 预览styles
    const [previewRootStyle, setPreviewRootStyle] = useState({});
    const handleChange = useCallback((e) => {
        setHtml(e.getHtml());
        onChange(e.getHtml());
    }, [onChange]);
    const handlePreView = useCallback(() => {
        setVisible(true);
        const innerZoom = 750 / (document.documentElement.clientWidth * window.devicePixelRatio || 1);
        setPreviewRootStyle({ zoom: innerZoom });
    }, []);
    // if (editor) {
    //   const tool = DomEditor.getToolbar(editor)
    //   console.log('get1111111--->', tool?.getConfig().toolbarKeys);
    // }
    // 工具栏配置
    const toolbarConfig = useMemo(() => {
        return {
            excludeKeys: toolbarConfigExcludeKeys,
        };
    }, []);
    // 编辑器配置
    const editorConfig = useMemo(() => {
        return {
            MENU_CONF: {
                ...defaultEditorConfig.MENU_CONF,
                editLink: {
                    checkLink: customCheckLinkFn, // 也支持 async 函数
                },
                uploadImage: {
                    async customUpload(file, insertFn) {
                        // TS 语法
                        const ossInfo = await getOssKey({});
                        await ossUpload(ossInfo, file, file.name);
                        // async customUpload(file, insertFn) {                   // JS 语法
                        // file 即选中的文件
                        // 自己实现上传，并得到图片 url alt href
                        // 最后插入图片
                        insertFn(ossInfo.key, file.name, ossInfo.key);
                    },
                },
            },
        };
    }, []);
    useEffect(() => {
        if (value) {
            setHtml(value);
        }
        else if (placeholder && (!value || value === '<p><br></p>')) {
            setHtml(`<p>${placeholder}</p>`);
        }
    }, [placeholder, value]);
    // 及时销毁 editor ，重要！
    useEffect(() => {
        return () => {
            if (editor == null)
                return;
            editor.destroy();
            setEditor(null);
        };
    }, [editor]);
    useEffect(() => {
        document.getElementsByTagName('html')[0].style.fontSize = 'calc(100vw / 7.5)';
        // if (document.querySelector('.fix-w-e-text-container .w-e-text-container')) {
        //   const dom: any = document.querySelector(
        //     '.fix-w-e-text-container .w-e-text-container',
        //   );
        //   dom.style.zoom =
        //     750 /
        //     (document.documentElement.clientWidth * window.devicePixelRatio || 1);
        // }
        return () => {
            document.getElementsByTagName('html')[0].style.fontSize = 'normal';
        };
    }, []);
    return (_jsxs("div", { children: [_jsx("div", { className: styles.previewBtnBox, children: _jsx(CnButton, { type: 'primary', onClick: handlePreView, children: previewBtnText }) }), _jsxs("div", { style: { border: '1px solid #ccc', zIndex: 100 }, className: styles.previewRoot, children: [_jsx(Toolbar, { editor: editor, defaultConfig: toolbarConfig, mode: "default", style: { borderBottom: '1px solid #ccc' } }), _jsx(Editor, { defaultConfig: editorConfig, value: html, onCreated: setEditor, onChange: handleChange, mode: "default", style: { overflowY: 'hidden', ...editorStyle }, className: 'fix-w-e-text-container' }), _jsx(CnDialog, { title: title, size: "medium", visible: visible, onClose: () => setVisible(false), onCancel: () => setVisible(false), onOk: () => {
                            setVisible(false);
                        }, style: { width: '798px' }, children: _jsx("div", { className: styles.previewRoot, style: { ...previewRootStyle }, children: _jsx("div", { dangerouslySetInnerHTML: { __html: html } }) }) })] })] }));
}
const DWEditor = View;
DWEditor.displayName = COMPONENT_NAME_ENUM.DW_EDITOR;
export { DWEditor };
