/**
 * 将 api setter 的配置转为 cn-ui 标准 service 配置
 */
export const transformRequestConfig = (config, state) => {
    if (config && state) {
        const service = {};
        service.url = config.url;
        if (config.timeout) {
            service.timeout = config.timeout;
        }
        if (service.serviceType === 'http') {
            service.method = config.method;
            service.contentType = config.contentType;
        }
        else {
            service.method = 'post';
            service.contentType = 'application/json;charset=UTF-8';
        }
        const reqParams = {};
        if (config.params?.length > 0) {
            config.params.forEach((item) => {
                // console.log('item==========>', item);
                if (item.value.group === '__dataSource__') {
                    if (state && state[item.value.param]) {
                        const val = state[item.value.param][item.value.secondParam];
                        if (Array.isArray(val)) {
                            reqParams[item.label] = [...val];
                        }
                        else if (typeof val === 'object') {
                            reqParams[item.label] = { ...val };
                        }
                        else {
                            reqParams[item.label] = val;
                        }
                    }
                }
                else {
                    reqParams[item.label] = item.value;
                }
            });
        }
        if (service.method === 'get') {
            service.params = reqParams;
        }
        else {
            service.data = reqParams;
        }
        if (config.resultProcessFunc) {
            service.transformResponse = [
                function (res) {
                    const res2 = JSON.parse(res);
                    // console.log('res2=========>', res2);
                    return config.resultProcessFunc(res2);
                },
            ];
        }
        return service;
    }
    else {
        return null;
    }
};
export const isEmpty = (obj) => {
    return obj === null || obj === undefined || obj === '' || obj === 'null' || obj === 'undefined';
};
export function getRealResponse(res = {}) {
    const { status, data } = res;
    let result = res;
    if (status === 200 && data) {
        result = data;
    }
    return result;
}
export function replacePxWithRem(match, p1) {
    return `font-size: ${p1 / 100}rem`;
}
// 定义rem基准值
const sizeBase = 100;
// 定义输入转换函数
export const unitImportFn = (unit) => {
    // type为单位类型，例如font-size等
    // source为输入来源，可能值为create或paste
    // 此函数的返回结果，需要过滤掉单位，只返回数值
    if (unit.indexOf('rem')) {
        return parseFloat(unit) * sizeBase;
    }
    else {
        return parseFloat(unit);
    }
};
// 定义输出转换函数
export const unitExportFn = (unit, type, target) => {
    if (type === 'line-height') {
        // 输出行高时不添加单位
        return unit;
    }
    // target的值可能是html或者editor，对应输出到html和在编辑器中显示这两个场景
    if (target === 'html') {
        // 只在将内容输出为html时才进行转换
        return unit / sizeBase + 'rem';
    }
    else {
        // 在编辑器中显示时，按px单位展示
        return unit + 'px';
    }
};
