/**
 * File: cn-biz-commerce-components-tool.ts
 * Author: zhangruqian
 * Date: 2023/11/23
 * Description: 工具函数
 */
import { MP4_TYPE_CONFIG, IMAGE_TYPE_CONFIG } from './config';
export const isMp4File = (type) => {
    return MP4_TYPE_CONFIG.includes(type);
};
export const isImage = (type) => {
    return IMAGE_TYPE_CONFIG.includes(type);
};
