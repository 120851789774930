import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
 * @author wb-zrq481890
 * @version 1.0.0
 * @Description https://braft.margox.cn/
 * @Demo https://braft.margox.cn/demos/rem
 * @create 2023/6/26
 */
import { useCallback, useEffect, useRef, useState } from 'react';
import { unitImportFn, unitExportFn } from '@/utils/common';
import { useRequest } from 'ahooks';
import { Box, CnButton, CnDialog, CnMessage } from '@cainiaofe/cn-ui';
import CustomQRCode from '@/components/slate-editor/qr-code';
// 引入编辑器组件
import BraftEditor from 'braft-editor';
// 引入编辑器样式
import 'braft-editor/dist/index.css';
const controls = [
    'font-size',
    'line-height',
    'letter-spacing',
    'separator',
    'text-color',
    'bold',
    'italic',
    'underline',
    'strike-through',
    'separator',
    'text-indent',
    'text-align',
    'separator',
    'list-ul',
    'list-ol',
    'blockquote',
    'separator',
    'link',
    'separator',
    'hr',
    'separator',
    'media',
    'separator',
    'clear',
];
const accepts = {
    image: 'image/png,image/jpeg,image/jpg,,image/gif,image/webp,image/apng,image/svg',
    video: false,
    audio: false,
};
const win = window;
function View(props) {
    const { value, placeholder, readOnly, editorStyle, onChange, requestConfig, _context, previewBtnText, btnType, justify, link, errorMessage, } = props;
    const editor = useRef(null);
    const [innerVisible, setInnerVisible] = useState(false);
    const saveTextAsync = useCallback(async (innerParam) => {
        try {
            // makeRequest方法return会一个Promise对象
            const res = await win.cnLowCodeUtils?.request?.makeRequest?.({
                // 请求成功后是否toast提示
                needSuccessToast: false,
                buttonConfig: {
                    options: {
                        requestConfig: {
                            ...requestConfig,
                            params: [
                                {
                                    label: 'text',
                                    value: innerParam?.text,
                                },
                                ...requestConfig?.params,
                            ],
                        },
                    },
                },
                recordDataSource: {},
                state: _context?.state || {},
                // 设置额外请求参数的回调
                handleParams: () => {
                    return {};
                },
            });
            const { success, data } = res || {};
            if (success) {
                setInnerVisible(!!data.token);
                return {
                    data: link ? `${link}?token=${data.token || ''}` : '',
                };
            }
            return {
                data: '',
            };
        }
        catch (e) {
            console.error('请求错误', e);
            return {
                data: '',
            };
        }
    }, [_context?.state, link, requestConfig]);
    const { runAsync: saveTextRunAsync, loading: saveLoading, data: createData, } = useRequest(saveTextAsync, { manual: true });
    const handleSave = useCallback(async () => {
        const _text = editor.current.getValue()?.toHTML();
        if (errorMessage && _text === '<p></p>') {
            CnMessage.error(errorMessage);
        }
        else if (_text !== '<p></p>' && errorMessage) {
            await saveTextRunAsync({ text: _text });
        }
        else {
            await saveTextRunAsync({ text: _text });
        }
    }, [errorMessage, saveTextRunAsync]);
    const changeHandle = useCallback((content) => {
        onChange(content?.toHTML());
    }, [onChange]);
    useEffect(() => {
        if (value && editor.current && editor.current.getValue()?.toHTML() !== value) {
            editor.current.setValue(BraftEditor.createEditorState(value));
        }
    }, [editor, value]);
    return (_jsxs("div", { children: [_jsxs("div", { children: [_jsx(Box, { direction: 'row', style: { paddingBottom: 8 }, justify: justify, children: _jsx(CnButton, { type: btnType, onClick: handleSave, loading: saveLoading, children: previewBtnText }) }), _jsx(BraftEditor
                    // value={innerValue}
                    , { 
                        // value={innerValue}
                        onChange: changeHandle, placeholder: placeholder, readOnly: readOnly, controls: controls, contentStyle: {
                            boxShadow: 'inset 0 1px 3px rgba(0,0,0,.1)',
                            ...editorStyle,
                        }, converts: { unitImportFn, unitExportFn }, ref: editor, media: { accepts } })] }), _jsx(CnDialog, { visible: innerVisible, title: previewBtnText, size: 'small', footer: false, onClose: () => {
                    setInnerVisible(false);
                }, children: _jsx(CustomQRCode, { value: createData?.data, size: 310 }) })] }));
}
const DWSlateEditor = View;
DWSlateEditor.displayName = 'DWSlateEditor';
export { DWSlateEditor };
