/**
 * File: cn-biz-commerce-components-config.ts
 * Author: zhangruqian
 * Date: 2023/11/23
 * Description: 编辑器配置
 */
export const controls = [
    'font-size',
    'line-height',
    'letter-spacing',
    'separator',
    'text-color',
    'bold',
    'italic',
    'underline',
    'strike-through',
    'separator',
    'text-indent',
    'text-align',
    'separator',
    'list-ul',
    'list-ol',
    'blockquote',
    'separator',
    'link',
    'separator',
    'hr',
    'separator',
    'media',
    'separator',
    'clear',
];
export const accepts = {
    image: 'image/png,image/jpeg,image/jpg,image/gif,image/webp,image/apng,image/svg',
    video: false,
    audio: false,
};
export const MP4_TYPE_CONFIG = ['video/mp4'];
export const IMAGE_TYPE_CONFIG = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/gif',
    'image/webp',
    'image/apng',
    'image/svg',
];
