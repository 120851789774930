// 初始化项目的时候填入的变量, 请谨慎修改, 会影响各个地方的使用
export const componentName = 'CN_BIZ_COMMERCE_COMPONENTS';
export const libraryName = 'CN_BIZ_COMMERCE_COMPONENTS';
export const categoryName = '地网商业化业务组件';
export var COMPONENT_NAME_ENUM;
(function (COMPONENT_NAME_ENUM) {
    COMPONENT_NAME_ENUM["DW_BRAFT_EDITOR_PC"] = "DWBraftEditorPc";
    COMPONENT_NAME_ENUM["DW_DETAIL_ITEM"] = "DWDetailItem";
    COMPONENT_NAME_ENUM["DW_DIALOG_TABLE_CHECK"] = "DWDialogTableCheck";
    COMPONENT_NAME_ENUM["DW_EDITOR"] = "DWEditor";
    COMPONENT_NAME_ENUM["DW_BRAFT_EDITOR_H5"] = "DWBraftEditorH5";
})(COMPONENT_NAME_ENUM || (COMPONENT_NAME_ENUM = {}));
