import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import $i18n from '@alife/panda-i18n'; /* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CnFilterItem, CnInput, CnAsyncSelect, CnTreeSelect, CnDatePickerPro, } from '@cainiaofe/cn-ui';
function FilterItem({ config = [] }) {
    const itemsElement = [];
    config.map((item, index) => {
        const { label, name, type, isHidden = false, props } = item;
        const key = `${type}_${index}`;
        let curItem = _jsx(_Fragment, {});
        switch (type) {
            case 'input':
                curItem = (_jsx(CnFilterItem, { label: label, name: name, children: _jsx(CnInput, { placeholder: $i18n.get({
                            id: 'PleaseEnterLabel',
                            dm: '请输入{label}',
                            ns: 'DialogTableCheck',
                        }, { label }), ...props }) }, key));
                break;
            case 'asyncSelect':
                curItem = (_jsx(CnFilterItem, { label: label, name: name, children: _jsx(CnAsyncSelect, { ...props }) }, key));
                break;
            case 'treeSelect':
                curItem = (_jsx(CnFilterItem, { label: label, name: name, children: _jsx(CnTreeSelect, { ...props }) }, key));
                break;
            case 'datePicker':
                curItem = (_jsx(CnFilterItem, { label: label, name: name, children: _jsx(CnDatePickerPro, { ...props }) }, key));
                break;
            default:
                break;
        }
        if (isHidden) {
            curItem = _jsx("p", { style: { height: 0, width: 0, overflow: 'hidden' }, children: curItem });
        }
        itemsElement.push(curItem);
    });
    return itemsElement;
}
export default FilterItem;
