export const defaultEditorConfig = {
    MENU_CONF: {
        fontSize: {
            fontSizeList: [
                { name: '24', value: '0.24rem' },
                { name: '30', value: '0.30rem' },
                { name: '32', value: '0.32rem' },
                { name: '34', value: '0.34rem' },
                { name: '36', value: '0.36rem' },
                { name: '40', value: '0.40rem' },
                { name: '44', value: '0.44rem' },
                { name: '46', value: '0.46rem' },
            ],
        },
        lineHeight: {
            lineHeightList: ['1', '1.5', '2', '2.5'],
        },
    },
};
