import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import $i18n from '@alife/panda-i18n'; /* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
// import { withI18n } from '@alife/panda-i18n';
// import locale from 'src/locale';
import { CnFilter, CnDialog, CnTable, Field } from '@cainiaofe/cn-ui';
import { COMPONENT_NAME_ENUM } from '@/const';
import styles from './index.module.scss';
import FilterItem from './filter-item';
const { useState, useEffect } = React;
function View({ title = $i18n.get({ id: 'PleaseSelect', dm: '请选择', ns: 'DialogTableCheck' }), filterConfig = [], url = '', method = 'POST', primaryKey = '', columns = [], value = {}, showKey = '', isSingle = true, readOnly = false, onChange = () => { }, }) {
    const [visible, setVisible] = useState(false);
    const [params, setParams] = useState({});
    const [showRecord, setShowRecord] = useState(value);
    let selectedRecord = value;
    const [tableData, setTableData] = useState([]);
    const filterField = Field.useField({
        values: {},
    });
    useEffect(() => {
        const defaultParams = {};
        filterConfig.forEach((item) => {
            const { props } = item;
            const props2 = props;
            if (props2.hasOwnProperty('value')) {
                defaultParams[item.name] = props2.value;
            }
        });
        // console.log('defaultParams======>', defaultParams);
        setParams(defaultParams);
    }, [filterConfig]);
    // filterConfig = [
    //   {
    //     label: '搜索关键字',
    //     type: 'input',
    //     name: 'keyWords',
    //     props: {
    //       placeholder: '请输入关键字',
    //     },
    //   },
    //   {
    //     label: '选择商品1',
    //     type: 'asyncSelect',
    //     name: 'goods1',
    //     props: {
    //       dataSource: [
    //         {
    //           label: '铅笔',
    //           value: '10001',
    //         },
    //         {
    //           label: '手机',
    //           value: '10002',
    //         },
    //         {
    //           label: '篮球',
    //           value: '10003',
    //         },
    //       ],
    //       showSearch: true,
    //       filterLocal: false,
    //     },
    //   },
    //   {
    //     label: '选择商品2',
    //     type: 'asyncSelect',
    //     name: 'goods2',
    //     props: {
    //       remoteUrl:
    //         'https://rap2api.alibaba-inc.com/app/mock-scene/7053/api/select/cn-data-source?_tag=mock',
    //       showSearch: true,
    //       filterLocal: false,
    //     },
    //   },
    //   {
    //     label: '选择地区1',
    //     type: 'treeSelect',
    //     name: 'address1',
    //     props: {
    //       dataSource: [
    //         {
    //           label: '台湾',
    //           value: '4858',
    //           children: [
    //             {
    //               children: [{ label: '台北县', value: '4860' }],
    //               label: '台北县',
    //               value: '4859',
    //             },
    //             {
    //               children: [{ label: '宜兰县', value: '4862' }],
    //               label: '宜兰县',
    //               value: '4861',
    //             },
    //           ],
    //         },
    //       ],
    //       showSearch: true,
    //       filterLocal: false,
    //     },
    //   },
    //   {
    //     label: '选择地区2',
    //     type: 'treeSelect',
    //     name: 'address2',
    //     props: {
    //       requestConfig: {
    //         url: 'https://oneapi.alibaba-inc.com/mock/kuw2pbp6/api/customaddress',
    //       },
    //       showSearch: true,
    //       filterLocal: false,
    //     },
    //   },
    //   {
    //     label: '选择日期',
    //     type: 'datePicker',
    //     name: 'date',
    //     props: {
    //       showTime: false,
    //       format: 'YYYY/MM/DD',
    //     },
    //   },
    //   {
    //     label: '选择时间',
    //     type: 'datePicker',
    //     name: 'time',
    //     props: {
    //       showTime: true,
    //       format: 'YYYY/MM/DD HH:mm:ss',
    //     },
    //   },
    // ];
    // url =
    //   'https://rap2api.alibaba-inc.com/app/mock/7053/api/pro-table/pagination-list';
    // primaryKey = 'quantity';
    // showKey = 'company';
    // columns = [
    //   {
    //     key: 'id',
    //     name: 'ID',
    //   },
    //   {
    //     key: 'company',
    //     name: '公司',
    //   },
    //   {
    //     key: 'cardNumber',
    //     name: '卡号',
    //   },
    //   {
    //     key: 'date',
    //     name: '日期',
    //   },
    // ];
    return (_jsxs("div", { className: styles.tableCheck, children: [_jsxs("div", { children: [showRecord[showKey] ? _jsx("p", { children: showRecord[showKey] }) : null, readOnly ? null : (_jsx("span", { onClick: () => setVisible(true), children: $i18n.get({ id: 'PleaseSelect', dm: '请选择', ns: 'DialogTableCheck' }) }))] }), _jsxs(CnDialog, { title: title, size: "autoLarge", visible: visible, onClose: () => setVisible(false), onCancel: () => setVisible(false), onOk: () => {
                    setShowRecord(selectedRecord);
                    onChange(selectedRecord);
                    setVisible(false);
                }, children: [_jsx(CnFilter, { onSubmit: (values) => {
                            setParams({ ...values });
                        }, onReset: () => {
                            filterField.reset();
                        }, children: FilterItem({ config: filterConfig }) }), _jsx("div", { children: _jsx(CnTable, { remote: {
                                url,
                                method,
                                params,
                                responseTransform(res) {
                                    let data = res.tableData;
                                    if (!data) {
                                        data = [];
                                    }
                                    setTableData(data);
                                    return res;
                                },
                            }, columns: columns, primaryKey: primaryKey, toolbar: {
                                settings: ['size', 'zebra'],
                            }, showSelect: true, selectType: isSingle ? 'single' : undefined, onSelectChange: (key) => {
                                tableData.forEach((record) => {
                                    if (record[primaryKey] === key) {
                                        // setSelectedRecord(record);
                                        selectedRecord = record;
                                    }
                                });
                            }, cellOverflowEllipsis: true, paging: true }) })] })] }));
}
const DWDialogTableCheck = View;
DWDialogTableCheck.displayName = COMPONENT_NAME_ENUM.DW_DIALOG_TABLE_CHECK;
export { DWDialogTableCheck };
